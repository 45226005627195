var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.iponeStatus)?_c('div',{staticClass:"curriculum flex-column-between"},[_c('div',{staticClass:"flex-align-between head"},[_c('div',{staticClass:"route"},[_vm._v("当前位置："+_vm._s(_vm.$route.query.title)+"作品集合")]),_c('div',{staticClass:"back flex-align"},[_c('img',{attrs:{"src":require('../../assets/task/return.png')},on:{"click":_vm.goback}}),_c('span',{on:{"click":_vm.goback}},[_vm._v("返回")])])]),_c('div',{staticClass:"main"},[_c('ul',_vm._l((Object.keys(_vm.worksList).sort().reverse()),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"courseDate"},[_c('span',[_vm._v(_vm._s(item))]),_c('span',[_vm._v(_vm._s(_vm.weeks[new Date(item).getDay()]))])]),_c('el-timeline',{attrs:{"simple":false}},_vm._l((_vm.worksList[item]),function(itemSon,indexSon){return _c('el-timeline-item',{key:indexSon,attrs:{"timestamp":_vm.renderTime(itemSon.startTime || itemSon.uploadTime),"placement":"top"}},[_c('div',{staticClass:"courseware flex-align-around",class:{
                coursewarHover:
                  _vm.addHover &&
                  _vm.qrcodeIndexSon == indexSon &&
                  _vm.qrcodeIndex == index,
              },on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.options
                  ? _vm.goWord(
                      itemSon.lessonId,
                      itemSon.classId,
                      itemSon.wordId,
                      itemSon.workName?itemSon.workName:itemSon.wordName,
                    )
                  : _vm.goWordPersonal(itemSon.workName)},"mouseenter":function($event){if($event.target !== $event.currentTarget)return null;return _vm.enter(indexSon, index)},"mouseleave":function($event){if($event.target !== $event.currentTarget)return null;return _vm.leave(indexSon, index)}}},[_c('div',{staticClass:"type flex-align-between",on:{"click":function($event){_vm.options
                    ? _vm.goWord(
                      itemSon.lessonId,
                      itemSon.classId,
                      itemSon.wordId,
                      itemSon.workName?itemSon.workName:itemSon.wordName,
                      )
                    : _vm.goWordPersonal(itemSon.workName)}}},[(_vm.options)?_c('span',[_vm._v(_vm._s(itemSon.className)+" 【"+_vm._s(itemSon.workName?itemSon.workName:itemSon.wordName)+"】 的学习")]):_c('span',[_vm._v("【"+_vm._s(itemSon.workName?itemSon.workName:itemSon.wordName)+"】的学习")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"UploadStatus flex-align-between",on:{"click":function($event){_vm.options
                    ? _vm.goWord(
                       itemSon.lessonId,
                      itemSon.classId,
                      itemSon.wordId,
                      itemSon.workName?itemSon.workName:itemSon.wordName,
                      )
                    : _vm.goWordPersonal(itemSon.workName)}}},[_c('img',{attrs:{"src":itemSon.uploadCount == 0
                      ? _vm.UploadStatusImg1
                      : _vm.UploadStatusImg2}}),_c('span',[_vm._v(_vm._s(itemSon.uploadCount == 0 ? "未上传" : "已上传"))])]),_c('div',{staticClass:"worksNum flex-align-between",on:{"click":function($event){_vm.options
                    ? _vm.goWord(
                       itemSon.lessonId,
                      itemSon.classId,
                      itemSon.wordId,
                      itemSon.workName?itemSon.workName:itemSon.wordName,
                      )
                    : _vm.goWordPersonal(itemSon.workName)}}},[_vm._v(" "+_vm._s(itemSon.uploadCount || itemSon.count)+"份作品 ")]),(false)?_c('div',{staticClass:"upload flex-align-between",class:`${index + 'uploadTip' + indexSon}`,on:{"click":function($event){return _vm.qrcodeUpload(
                    `${index + 'uploadTip' + indexSon}`,
                    itemSon.uploadCount,
                    itemSon.lessonId,
                    itemSon.mpCodePath,
                    itemSon.classId,
                    itemSon.wordId,
                    itemSon.wordName
                  )}}},[_c('img',{attrs:{"src":require("@/assets/showreel/uploading.png")}}),_c('span',{staticClass:"uploadText"},[_vm._v("上传")]),(
                    _vm.qrstatus &&
                    _vm.identification == `${index + 'uploadTip' + indexSon}`
                  )?_c('div',{staticClass:"uploadTipQr flex-column-center"},[_c('div',{staticClass:"container flex-align-center"},[_c('img',{attrs:{"src":itemSon.mpCodePath}})]),_c('span',[_vm._v("微信扫码")]),_c('span',[_vm._v("上传本课作品")])]):_vm._e()]):_vm._e()]),(!_vm.DleBtn)?_c('div',{staticClass:"delSet",on:{"click":function($event){return _vm.checkedBox(_vm.options ? itemSon.lessonId : itemSon.workName)}}},[_c('img',{attrs:{"src":[
                  _vm.arr.includes(itemSon.lessonId) ||
                  _vm.arr.includes(itemSon.workName)
                    ? require('@/assets/showreel/delB.png')
                    : require('@/assets/showreel/delA.png'),
                ]}})]):_vm._e()])}),1)],1)}),0),(Object.keys(_vm.worksList).sort().reverse().length == 0)?_c('div',{staticClass:"placeholder flex-column-center"},[_c('div'),_c('span',[_vm._v("暂无内容")])]):_vm._e()]),_c('div',{staticClass:"base"}),_c('subscribe',{attrs:{"dialogVisible":_vm.centerDialogVisible2},on:{"clear":_vm.clear}})],1):_c('collect')
}
var staticRenderFns = []

export { render, staticRenderFns }