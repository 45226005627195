<template>
  <!-- 适配pc端页面 -->
  <div class="curriculum flex-column-between" v-if="iponeStatus">
    <div class="flex-align-between head">
      <div class="route">当前位置：{{$route.query.title}}作品集合</div>
      <div class="back flex-align">
        <img :src="require('../../assets/task/return.png')" @click="goback" />
        <span @click="goback">返回</span>
      </div>
    </div>
    <div class="main">
      <ul>
        <li v-for="(item, index) in Object.keys(worksList).sort().reverse()" :key="index">
          <div class="courseDate">
            <span>{{ item }}</span>
            <span>{{ weeks[new Date(item).getDay()] }}</span>
          </div>
          <el-timeline :simple="false">
            <el-timeline-item v-for="(itemSon, indexSon) in worksList[item]" :key="indexSon"
              :timestamp="renderTime(itemSon.startTime || itemSon.uploadTime)" placement="top">
              <div class="courseware flex-align-around" :class="{
                  coursewarHover:
                    addHover &&
                    qrcodeIndexSon == indexSon &&
                    qrcodeIndex == index,
                }" @click.self="
                  options
                    ? goWord(
                        itemSon.lessonId,
                        itemSon.classId,
                        itemSon.wordId,
                        itemSon.workName?itemSon.workName:itemSon.wordName,
                      )
                    : goWordPersonal(itemSon.workName)
                " @mouseenter.self="enter(indexSon, index)" @mouseleave.self="leave(indexSon, index)">
                <div class="type flex-align-between" @click="
                    options
                      ? goWord(
                        itemSon.lessonId,
                        itemSon.classId,
                        itemSon.wordId,
                        itemSon.workName?itemSon.workName:itemSon.wordName,
                        )
                      : goWordPersonal(itemSon.workName)
                  ">
                  <span v-if="options">{{ itemSon.className }}
                    【{{ itemSon.workName?itemSon.workName:itemSon.wordName }}】
                    的学习</span>
                  <span v-else>【{{ itemSon.workName?itemSon.workName:itemSon.wordName }}】的学习</span>
                </div>
                <div v-show="false" class="UploadStatus flex-align-between" @click="
                    options
                      ? goWord(
                         itemSon.lessonId,
                        itemSon.classId,
                        itemSon.wordId,
                        itemSon.workName?itemSon.workName:itemSon.wordName,
                        )
                      : goWordPersonal(itemSon.workName)
                  ">
                  <img :src="
                      itemSon.uploadCount == 0
                        ? UploadStatusImg1
                        : UploadStatusImg2
                    " />
                  <span>{{
                    itemSon.uploadCount == 0 ? "未上传" : "已上传"
                  }}</span>
                </div>
                <div class="worksNum flex-align-between" @click="
                    options
                      ? goWord(
                         itemSon.lessonId,
                        itemSon.classId,
                        itemSon.wordId,
                        itemSon.workName?itemSon.workName:itemSon.wordName,
                        )
                      : goWordPersonal(itemSon.workName)
                  ">
                  {{ itemSon.uploadCount || itemSon.count }}份作品
                </div>
                <div class="upload flex-align-between" v-if="false" :class="`${index + 'uploadTip' + indexSon}`" @click="
                    qrcodeUpload(
                      `${index + 'uploadTip' + indexSon}`,
                      itemSon.uploadCount,
                      itemSon.lessonId,
                      itemSon.mpCodePath,
                      itemSon.classId,
                      itemSon.wordId,
                      itemSon.wordName
                    )
                  ">
                  <img src="@/assets/showreel/uploading.png" />
                  <span class="uploadText">上传</span>
                  <div class="uploadTipQr flex-column-center" v-if="
                      qrstatus &&
                      identification == `${index + 'uploadTip' + indexSon}`
                    ">
                    <div class="container flex-align-center">
                      <img :src="itemSon.mpCodePath" />
                    </div>
                    <span>微信扫码</span>
                    <span>上传本课作品</span>
                  </div>
                </div>
              </div>
              <div class="delSet" @click="
                  checkedBox(options ? itemSon.lessonId : itemSon.workName)
                " v-if="!DleBtn">
                <img :src="[
                    arr.includes(itemSon.lessonId) ||
                    arr.includes(itemSon.workName)
                      ? require('@/assets/showreel/delB.png')
                      : require('@/assets/showreel/delA.png'),
                  ]" />
              </div>
            </el-timeline-item>
          </el-timeline>
        </li>
      </ul>
      <div class="placeholder flex-column-center" v-if="Object.keys(worksList).sort().reverse().length == 0">
        <div></div>
        <span>暂无内容</span>
      </div>
    </div>
    <div class="base"></div>
    <subscribe :dialogVisible="centerDialogVisible2" @clear="clear"></subscribe>
  </div>
  <!-- 适配移动端页面 -->
  <collect v-else></collect>
</template>

<script>
  import entrance from "../../Common_components/head/entrance";
  import subscribe from "../../Common_components/Popup/subscribe.vue";
  import collect from './collect.vue'
  import {
    mapState,
    mapMutations
  } from "vuex";
  export default {
    data() {
      return {
        changeable: true,
        options: true,
        worksList: [],
        weeks: [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ],
        qrcodeIndex: null,
        qrcodeIndexSon: null,
        addHover: false,
        UploadStatusImg1: require("../../assets/showreel/wait.png"),
        UploadStatusImg2: require("../../assets/showreel/accomplish.png"),
        qrstatus: false,
        identification: null,
        arr: [],
        DleBtn: true,
        centerDialogVisible2: false,
        iponeStatus: true
      };
    },
    components: {
      entrance,
      subscribe,
      collect
    },
    //方法
    methods: {
      ...mapMutations(["hint_data"]),
      renewal(value) {
        //页面销毁时关闭长连接
        // this.changeable = value;
        // this.imgArr = [];
        // this.throttle = 0;
        // this.viewerVal = 0;
        // this.resource = false;
        // sessionStorage.removeItem("Flush");
        // if (value && this.websock) {
        //       this.websocketclose();
        //       this.websock.send(this.closeJunction);
        // }
      },
      clear(bool) {
        this.centerDialogVisible2 = bool;
      },
      changTip(value) {
        if (value) {
          this.hint_data("暂无活动");
          this.centerDialogVisible2 = true;
        }
      },
      //学生作品集
      stuWorks() {
        this.options = true;
        this.arr = [];

        this.getTeacherWorkHistory();
        // sessionStorage.setItem("Flush", JSON.stringify(this.wordData));
      },
      //获取课时列表数据 --学生作品集合
      async getTeacherWorkHistory() {
        let resData = await this.$Api.Myclass.getTeacherWorkHistory();
        this.worksList = resData.data;
      },
      //我的作品集
      personalWorks() {
        this.options = false;
        this.DleBtn = true;
        this.arr = [];
        this.getQQTestList();
      },
      //我的作品集合
      async getQQTestList() {
        let data = {
          role: 1,
          activityId: this.$route.query.activityId
        }
        let resData = await this.$Api.Myclass.getQQTestList(data);
        console.log('我的作品集', resData)
        this.worksList = resData.data;
      },
      async getAPPQQTestList() {
        let data = {
          role: 1,
          activityId: this.$route.query.activityId
        }
        let resData = await this.$Api.Myclass.getQQTestList(data);
        for (const key in resData.data) {
          resData.data[key].map(item => {
            let obj = {
              count: item.count,
              workName: item.workName
            }
            this.worksList.push(obj)
          })
        };
      },
      renderTime(date) {
        if (this.options == true) {
          var dateee = new Date(date).toJSON();
          let data = new Date(+new Date(dateee) + 8 * 3600 * 1000)
            .toISOString()
            .replace(/T/g, " ")
            .replace(/\.[\d]{3}Z/, "");
          data = data.slice(10, 19);
          return data;
        } else {
          let data = date.slice(10, 19);
          return data;
        }
      },
      //高亮
      enter(indexSon, index) {
        this.qrcodeIndexSon = indexSon;
        this.qrcodeIndex = index;
        this.addHover = true;
      },
      leave(indexSon, index, lessonId) {
        this.qrcodeIndexSon = indexSon;
        this.qrcodeIndex = index;
        this.addHover = false;
      },
      //跳转到学生作品集合
      goWord(lessonId, classId, wordId, wordName) {
        console.log(wordName)
        if (this.$route.query.type == 'initial') {
          this.$router.push({
            path: "/studywordsEvaluation",
            query: {
              type: 0,
              lessonId: lessonId,
              classId: classId,
              wordId: wordId,
              wordName: wordName,
              // switchIndex: this.switchIndex
            },
          });
        } else {
          this.$router.push({
            path: `/studywordsEvaluation?token=${sessionStorage.token}&worksName=${wordName}&from=studywordEvaluation&activityId=${this.$route.query.activityId}`
          })
        }

      },
      //跳转到我的作品
      goWordPersonal(lessonName) {},
      qrcodeUpload(value, num, lessonId, mpCodePath, classId, wordId, wordName) {
        let that = this;
        if (num == 0 && mpCodePath == null) {
          this.goWord(lessonId, classId, wordId, wordName);
        } else if (mpCodePath != null && num == 0) {
          let img = new Image();
          img.src = mpCodePath;
          img.onload = function () {
            that.identification = value;
            that.qrstatus = true;
          };
          img.onerror = function () {
            that.goWord(lessonId, classId, wordId, wordName);
          };
        } else if (mpCodePath == null && num != 0) {
          this.goWord(lessonId, classId, wordId, wordName);
        } else if (mpCodePath != null && num != 0) {
          let img = new Image();
          img.src = mpCodePath;
          img.onload = function () {
            that.identification = value;
            that.qrstatus = true;
          };
          img.onerror = function () {
            that.goWord(lessonId, classId, wordId, wordName);
          };
        }
      },
      checkedBox(value) {
        if (this.arr.includes(value)) {
          this.arr = this.arr.filter(function (ele) {
            return ele != value;
          });
        } else {
          this.arr.push(value);
        }
      },
      //删除选项
      showDleBtn() {
        this.DleBtn = false;
      },
      //取消按钮
      cancel() {
        this.DleBtn = true;
        this.arr = [];
      },
      //删除按钮
      del() {
        let _this = this;
        if (this.arr.length == 0) return;
        this.$confirm(
            "删除所选课程记录，同时所包含作品也将被清除，确定要删除吗？",
            "确认删除", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              customClass: "MessClass",
              confirmButtonClass: "warningClass",
              center: true,
            }
          )
          .then(() => {
            _this.options ?
              _this.deleteTeacherWork(_this.arr) :
              _this.deleteQQWork(_this.arr);
          })
          .catch(() => {
            _this.arr = [];
          });
      },
      //删除时间轴学生作品列表
      async deleteTeacherWork(arr) {
        let data = {
          ids: arr.toString(),
        };
        let resData = await this.$Api.Myclass.deleteTeacherWork(data);
        if (resData.code == 200) {
          this.$message({
            message: "删除成功",
            center: true,
            type: "success",
          });
          this.arr = [];
          this.getTeacherWorkHistory();
        }
      },
      //删除时间轴我的作品列表
      async deleteQQWork(arr) {
        let data = {
          ids: arr.toString(),
        };
        let resData = await this.$Api.Myclass.deleteQQWork(data);
        if (resData.code == 200) {
          this.$message({
            message: "删除成功",
            center: true,
            type: "success",
          });
          this.arr = [];
          this.getQQTestList();
        }
      },
      goback() {
        this.$router.go(-1);
      },
      goAItest(workName) {
        console.log('跳转')
        let query = {
          workName
        }
        this.$router.push({
          path: '/aitest',
          query
        })
      },
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {},
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
      localStorage.removeItem("loglevel:webpack-dev-server")
      console.log(localStorage.getItem("loglevel:webpack-dev-server"))
      //获取当前分辨率
      if (document.body.clientWidth < 600) {
        this.iponeStatus = false;
      } else {
        this.iponeStatus = true;
      }
      window.onresize = () => {
        console.log('浏览器大小被重置')
        return (() => {
          if (document.body.clientWidth < 600) {
            this.iponeStatus = false;
          } else {
            this.iponeStatus = true;
          }
        })();
      };
      if (this.$route.query.type == 'initial') {
        // 互动课堂作品集
        this.stuWorks()
      } else {
        // 获取研修专家角色活动管理中的作品集
        if (this.iponeStatus) {
          this.getQQTestList()
        } else {}
      }

      console.log(localStorage.getItem("loglevel:webpack-dev-server"))
    },
  };
</script>
<style lang='less' scoped>
  @import "./less/studyCurriculum.less";
</style>