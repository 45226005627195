<!-- 作品集列表 -->
<template>
    <div class="work_list">
        <template v-if="worksList.length>0">
            <div class="activeItem flex-align-between" ref="myList" v-for="(item,index) in worksList" :key="index"
                @click="goAItest(item.workName)">
                <img class="balloon" :src="require('../../assets/task/balloon.png')" />
                <span class="title">{{item.workName}}</span>
                <span class="num"><span style="color:#FF655C">{{item.count}}</span>幅作品</span>
            </div>
        </template>
        <el-empty description="暂无作品" :image-size="100" v-else></el-empty>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                worksList: []
            }
        },
        methods: {
            goAItest(workName) {
                console.log('跳转')
                let query = {
                    workName
                }
                this.$router.push({
                    path: '/aitest',
                    query
                })
            },
            async getQQTestList() {
                let data = {
                    role: 1,
                    activityId: this.$route.query.activityId
                }
                let resData = await this.$Api.Myclass.getQQTestList(data);
                for (const key in resData.data) {
                    resData.data[key].map(item => {
                        let obj = {
                            count: item.count,
                            workName: item.workName
                        }
                        this.worksList.push(obj)
                    })
                };
            },
        },
        mounted() {
            this.getQQTestList();
        },
    };
</script>
<style lang='less' scoped>
    @import url('./less/collect.less');
</style>